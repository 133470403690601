import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/8-min.jpg'
import styles from './post-grid.module.css'

const VegeMenu = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Vegetariánské menu | NutritionPro'}
        description={
          'S lednem tohoto roku přichází novinka, kterou jistě ocení nejeden masový odpůrce. Vegetariánské menu od NutritionPro je nabité energií, bílkovinami a všemi živinami, které vaše tělo potřebuje!'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Vegetariánské menu
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Vegetariánské menu" date="08.02.2023" />
            <div>
              <h5 className={styles.postTitle}>
                Novinka - vegetariánské menu NutritioPro
              </h5>
              <p className={styles.postText}>
                S lednem tohoto roku přichází novinka, kterou jistě ocení
                nejeden masový odpůrce.{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  Vegetariánské menu od NutritionPro
                </a>{' '}
                je{' '}
                <b>
                  nabité energií, bílkovinami a všemi živinami, které vaše tělo
                  potřebuje!
                </b>
              </p>

              <p className={styles.postText}>
                A nejedná se o jen tak ledajaké menu. Naše vegetariánské
                krabičky byly webem{' '}
                <a
                  href="https://www.testado.cz/vegetarianske-krabickove-diety/"
                  target="_blank"
                >
                  Testado
                </a>{' '}
                označeny jako{' '}
                <b>nejlepší vegetariánská krabičková dieta roku 2023</b>! Pokud
                se tedy obáváte, že bezmasé stravování není{' '}
                <b>plnohodnotné, pestré a chutné,</b> naše krabičky váš okamžitě
                vyvedou z omylu. A proč vlastně vegetariánství vyzkoušet?
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="img1"
              />
              <h5 className={styles.postTitle}>Něco málo o vegetariánství</h5>
              <p className={styles.postText}>
                Vegetariánství patří pod <b>alternativní výživové směry,</b> to
                znamená, že se nějakým způsobem odlišuje od obecně
                doporučovaných zásad stravování. Z jídelníčku vegetariánů je
                kompletně vyloučeno maso. Tento způsob stravování vychází z
                filozofických směrů <b>buddhismu a hinduismu,</b> dnešní důvody
                pro volbu vegetariánství jsou ovšem obvykle důvody <b>etické</b>{' '}
                nebo <b>snaha o zlepšení životního stylu.</b> Rozlišujeme celou
                řadu typů vegetariánství, a to podle spektra živočišných
                potravin, které daná skupina odmítá konzumovat (např.
                pesko-vegetariánství, lakto-ovo vegetariánství nebo
                frutariánství).
              </p>
              <h5 className={styles.postTitle}>
                Výhody vegetariánského stravování
              </h5>
              <p className={styles.postText}>
                Určité formy vegetariánství s sebou <b>nesou řadu výhod.</b> V
                první řadě se dá předpokládat, že člověk, který se vydá tímto
                směrem, se snaží nějakým způsobem{' '}
                <b>vylepšit svůj životní styl,</b>tedy že se bude více
                soustředit na skladbu svého jídelníčku. Může také zařadit více
                fyzické aktivity, začít dostatečně spát, více se věnovat svému
                psychickému zdraví a tak dále. S těmito změnami se obvykle pojí
                to, že se člověk <b>začne cítit lépe.</b>
              </p>

              <p className={styles.postText}>
                Při tomto způsobu stravování je běžný{' '}
                <b>nižší příjem energie a živočišných tuků,</b> případně i cukru
                a soli. Naopak se může <b>zvýšit příjem vlákniny,</b> který je
                nejen u populace České republiky obvykle nedostatečný. Dále{' '}
                <b>příjem vody,</b> který by se s příjmem vlákniny měl
                automaticky navyšovat, a také se může zvýšit příjem určitých{' '}
                <b>vitaminů, minerálních látek a antioxidantů.</b>
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Proč ještě je důležité pít vodu?{' '}
                <a href="https://nutritionpro.cz/blog/post-3/" target="_blank">
                  Dozvíte se v tomto článku.
                </a>
              </p>

              <p className={styles.postText}>
                Je ovšem potřeba mít na mysli, že{' '}
                <b>každý člověk se stravuje individuálně</b> a přistupuje k
                vegetariánskému jídlu odlišně. U někoho představuje přechod na
                rostlinnou stravu změnu k lepšímu životnímu stylu, někdo pouze
                přestane jíst maso, aniž by svůj jídelníček jakkoliv
                modifikoval. Podle toho, jaký má konkrétní osoba k tomuto
                alternativnímu směnu stravování přístup, se potom projeví jeho
                výhody či nevýhody.{' '}
                <b>
                  Jestli chcete od vegetariánství zdravotní benefity, musíte se
                  zaměřit na celkový životní styl.
                </b>
              </p>

              <h5 className={styles.postTitle}>
                Na co si dát jako vegetarián pozor
              </h5>
              <p className={styles.postText}>
                Pokud se rozhodnete pro vegetariánství, měli byste si být vědomi
                rizik, která obnáší, a svůj jídelníčku tomu přizpůsobit. Při
                vegetariánské dietě{' '}
                <b>
                  může hrozit nedostatek esenciálních aminokyselin, a to
                  především kvůli nedostatečnému příjmu bílkovin.{' '}
                </b>
                Bílkoviny živočišného původu totiž obsahují celý profil
                esenciálních aminokyselin, nazýváme je proto bílkovinami
                plnohodnotnými. Oproti tomu rostlinné bílkoviny obvykle obsahují
                pouze některé esenciální aminokyseliny, je proto nutné jejich
                zdroje kombinovat, což je úkon, nad kterým je třeba se při
                vegetariánském stravování zamyslet a{' '}
                <b>věnovat skladbě jídelníčku zvláštní péči.</b> Pokud si nejste
                jisti, jaké zdroje bílkovin kombinovat, vyzkoušejte{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  naše vegetariánské krabičky
                </a>
                , se kterými budete mít vždy jistotu, že je váš denní příjem
                bílkovin optimální a plnohodnotný.
              </p>

              <p className={styles.postText}>
                Je také třeba dávat pozor na{' '}
                <b>příjem železa, zinku a vápníku.</b> U vitaminů hrozí
                nedostatečný příjem především u <b>vitaminu B12.</b> Deficit
                těchto makro i mikro živin hrozí především u striktních forem
                vegetariánství, jako je například veganství či frutariánství.
                Bavíme-li se o klasickém vegetariánství, tedy o bezmasém
                stravování, nejsou tato rizika tak zásadní, neboť nahrazení
                pouze masa není v dnešní době tak obtížné.
              </p>

              <p className={styles.postText}>
                Důležitý je při vegetariánském stravování také výběr konkrétních
                potravin.{' '}
                <b>Je nutné si nastudovat, co je vhodné místo masa jíst.</b>{' '}
                Pokud to totiž budou pouze ultra průmyslově zpracované, smažené
                nebo sacharidové náhražky, není možné docílit benefitů
                vegetariánského stravování.
              </p>

              <h5 className={styles.postTitle}>Závěrem</h5>
              <p className={styles.postText}>
                Pokud byste si z tohoto článku měli odnést jen jednu věc, mělo
                by to být to, že{' '}
                <b>u vegetariánství je nejdůležitější pestrost.</b> Ta se ovšem
                s limitovanými potravinami snižuje, je proto{' '}
                <b>nutné se o to víc snažit potraviny nahradit.</b> Věnovat čas
                a energii skladbě svého jídelníčku není vždy snadné, obzvláště
                tehdy, potřebuje-li speciální pozornost. Abyste si byli jisti,
                že je váš jídelníček plný bílkovin, vitaminů, minerálních látek
                a dalších důležitých živin, nechte to na nás a vyzkoušejte{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  vegetariánské krabičky NutritionPro
                </a>
                , které jsou <b>ideální volbou,</b> lať už s vegetariánstvím
                teprve začínáte, nebo máte s bezmasým stravováním zkušenosti.
                Inspirace není nikdy dost. Kromě toho, že jsou naše
                vegetariánská jídla pestrá a vyvážená, jsou také velmi chutná a
                přesvědčí vás o tom, že vegetariánské stravování{' '}
                <b>nemusí být vůbec nudné a jednotvárné.</b>{' '}
                <a href="https://nutritionpro.cz/">Vyzkoušejte sami!</a>
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default VegeMenu
